import { createSlice } from "@reduxjs/toolkit";
import { CartAPI } from "../apis/cartApi";
import { UserApi } from "../apis/userApi";

const initialState = {
    currentCart: [],
    shippingRates: [],
    packedBoxes: [],
    selectedShippingRate: null,
    selectedShippingAddress: null,
    addressList: [],
    cartData: {},
    taxData: {},
    selectedBrainTreeToken: {},
    totalPrice: 0,
    isProductDelete: false,
}

const CartDataSlices = createSlice({
    name: "cartData",
    initialState,
    reducers: {
        setCart: (state, { payload }) => {
            state.currentCart = payload;
        },
        setIsProductDelete: (state, { payload }) => {
            state.isProductDelete = payload;
        },
        setSelectedShippingRate: (state, { payload }) => {
            state.selectedShippingRate = payload;
        },
        setSelectedShippingAddress: (state, { payload }) => {
            state.selectedShippingAddress = payload;
        },
        setBrainTreeToken: (state, { payload }) => {
            state.selectedBrainTreeToken = payload;
        },
        setTotalPrice: (state, { payload }) => {
            state.totalPrice = payload;
        }
    },
    extraReducers(builder) {
        builder.addMatcher(CartAPI.endpoints.getCart.matchFulfilled, (state, { payload }) => {
            if (payload.status) {
                const { products } = payload.response;
                state.currentCart = products;
                state.cartData = payload.response;
            }
        }).addMatcher(CartAPI.endpoints.calculateShippingRate.matchFulfilled, (state, { payload }) => {
            if (payload.status) {
                const { taxData, finalRateList, packedBoxes } = payload.response;
                state.shippingRates = finalRateList;
                state.packedBoxes = packedBoxes;
                state.taxData = taxData;
            }
        }).addMatcher(CartAPI.endpoints.addToCart.matchFulfilled, (state, { meta, payload }) => {
            if (payload.status && !meta.arg.originalArgs.is_delete) {
                state.shippingRates = [];
                state.packedBoxes = [];
                state.taxData = {};
            }
        }).addMatcher(UserApi.endpoints.getUserAddress.matchFulfilled, (state, { payload }) => {
            if (payload.status) {
                state.addressList = payload.response;
            }
        });
    },
});

const { reducer, actions } = CartDataSlices;

export const { setCart, setSelectedShippingRate, setSelectedShippingAddress, setBrainTreeToken, setTotalPrice, setIsProductDelete } = actions;

export const getCurrentCart = state => state.cartData.currentCart;
export const getShippingRates = state => state.cartData.shippingRates;
export const getPackedBoxes = state => state.cartData.packedBoxes;
export const getSelectedShippingRate = state => state.cartData.selectedShippingRate;
export const getSelectedShippingAddress = state => state.cartData.selectedShippingAddress;
export const getAddressListData = state => state.cartData.addressList;
export const getCartData = state => state.cartData.cartData;
export const getTaxData = state => state.cartData.taxData;
export const getBrainTreeData = state => state.cartData.selectedBrainTreeToken;
export const totalPrice = state => state.cartData.totalPrice;
export const getIsProductDelete = state => state.cartData.isProductDelete;



export default reducer;